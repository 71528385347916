import { Link } from "gatsby"
import React, { useEffect, useState, useRef } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import "./KeyFeatures.scss"
import "./PropertySlider.scss"
import PlayVideo from "../../Play/PlayVideo"
import ReactMarkdown from "react-markdown/with-html"
import getVideoId from "get-video-id"
import {
  IconPhone,
  PrintIcon,
  ShareIcon,
  IconZoom,
  ScrollDownIcon,
} from "../../icons"

import { BookaValuationURL } from "../../utils"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import Slider from "react-slick"
import { Scrollbars } from "react-custom-scrollbars-2"
import $ from "jquery"
import Footer from "../../Footer/Footer"
import LocalArea from "../LocalArea/LocalArea"
import DefaultLocalarea from "../LocalArea/DefaaultLocalArea"
import PropertyCalculator from "../../../Components/calculators/mortgagecalc/property-mortgage"
import BuyerReport from "../../Static/ContactTeam/BuyerReport"
import PropertyReport from "../../Static/ContactTeam/PropertyReport"
import { navigate } from "@reach/router"

import RelatedProperty from "../RelatedProperty/RelatedProperty"
import Enquiry from "../Enquiry/Enquiry"
import PropertyDetailMap from "../../map/transport-map"
import BroadBandData from "../../map/broadband-data"
import SchoolsList from "../../map/schools-list"
import TransportList from "../../map/transport-list"
import SchoolMap from "../../map/school-map"
import FloorplanImg from "../../../images/PropertyDetails/floorplan.jpg"
import ImageTransform from "../../Common/ggfx-client/module/components/image-transform"
import { isIOS, isMobile, isMobileOnly, isTablet } from "react-device-detect"
import Share from "./share"
import { propTypes } from "react-bootstrap/esm/Image"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  easeFunc,
  delayItemsFromBottom,
  containerVariants,
  fadeInFromRight,
  fadeInFromTop,
  fadeInFromLeft,
  fadeInFromRight1,
  fadeInFromTop1,
  fadeInFromLeft1,
  delayItems,
  imageZoom,
} from "../../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"

import {
  ScrollingProvider,
  useScrollSections,
  useScrollSection,
  Section,
} from "react-scroll-section"
import LocatingMap from "../../map/LocatingMap"
// import { useQuery } from '@apollo/client';
// import gql from "graphql-tag"
// const GETOFFICE=  gql`
// query GetOfficeQuery ($Phone: String!){
//  ourOffices(where:{Office_Phone:$Phone}){
//      Office_Email
//      Office_Phone
//  }
// }
// `;
const PropertySlides = props => {
  const homeSection = useScrollSection("top")
  const detailSection = useScrollSection("detail")
  const floorplanSection = useScrollSection("floorplan")
  const mapSection = useScrollSection("map")
  const schoolSection = useScrollSection("school")
  const transportSection = useScrollSection("transport")
  const moreSection = useScrollSection("more")
  
  const myRefless = useRef(null)
  const readlessScroll = () => {
    myRefless.current.scrollIntoView()
  }
  const [active, setActive] = useState("read more")
  const handleAccordionClick = () => {
    if (active === "read more") {
      setActive("read less")
    } else {
      setActive("read more")
      // readlessScroll()
    }
  }
  const [open, setOpen] = useState(false)
  const [isPlay, setPlay] = useState(false)
  const [videoID, SetvideoID] = useState(false)
  const [ShowSlide, setShowSlide] = useState(false)
  const [Effect, setEffect] = useState(isMobile ? "fadeInUp" : "fadeInDown")
  const [isIpadLand, setisIpadLand] = useState(false)
  const [view, setView] = React.useState("default-map-mobile")
  window.addEventListener(
    "orientationchange",
    function () {
      // Announce the new orientation number
      //alert(window.orientation);
      if (window.orientation > 0) {
        setisIpadLand(true)
      } else {
        setisIpadLand(false)
      }
    },
    false
  )
  //alert(isIpadLand);
  useEffect(() => {
    setShowSlide(props.Slide1)
    $("html, body").scrollTop(0)
  }, [props.Slide1])

  //   console.log("%%%%%", ShowSlide)

  const HanldeSlideClick = value => {
    setShowSlide(value)
    $("html, body").scrollTop(0)
  }
  const SlideClose = value => {
    props.closeClick(value)
  }
  // const handleBack = () => {
  //   window?.history?.previous?.href()
  // }
  const mapmyRef = useRef(null)
  const mapexecuteScroll = () => {
    mapmyRef.current.scrollIntoView({ behavior: "smooth" })
  }

  const HanldeMapClick = value => {
    // alert(value);
    setView(value)
    mapexecuteScroll()
    // $(".map-module-detail").css({visibility: "hidden", display: 'table', height: 0, maxHeight: 0, minHeight: 0})
    // $("." + value + ".map-module-detail").css({display:'table', visibility: 'visible'})
    // $("html, body").animate(
    //   {
    //     scrollTop: $("." + value + ".map-module-detail").offset().top - 40,
    //   },
    //   "3000"
    // )
  }
  //
  const FloorplanImgMap = [
    {
      FloorPlanImg: FloorplanImg,
    },
    {
      FloorPlanImg: FloorplanImg,
    },
  ]
  //

  // Slider
  var sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
  }

  const [indexed, setIndex] = useState(1)
  const afterChangeHandler = d => {
    setIndex(d + 1)
  }
  // Slider

  // Banner image popup
  const [toggler, setToggler] = useState(false)
  // Banner image popup

  // Floorplan image popup
  const [togglerFloorplan, setTogglerFloorplan] = useState(false)
  // Floorplan image popup

  // Banner lightbox
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const openLighboxMobile = (e, ind) => {
    e.preventDefault()
    setPhotoIndex(ind)
    setIsOpen(true)
  }

  var lightImages2 =
    props.property_images && props.property_images.map(img => img.url)
  // Banner lightbox

  // Floorplan lightbox
  const [floorplanIndex, setFloorPlanIndex] = useState(0)
  const [isOpenFloor, setIsOpenFloor] = useState(false)

  const openLighboxMobileFloor = (e, ind) => {
    e.preventDefault()
    setFloorPlanIndex(ind)
    setIsOpenFloor(true)
  }
  var FloorPlanImage =
    props.property_floorplan && props.property_floorplan.map(img => img.url)

  // Floorplan lightbox

  const footer = () => {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: "smooth",
    })
  }

  const header = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  let processedImages2 = JSON.stringify({})
  if (props?.imagetransforms?.images_Transforms) {
    processedImages2 = props.imagetransforms.images_Transforms
  }
  let processedImages3 = JSON.stringify({})
  if (props?.imagetransforms?.floorplan_Transforms) {
    processedImages3 = props.imagetransforms.floorplan_Transforms
  }

  //console.log('aaaaaaaaaa',props.property_latitude,props.property_longitude)
  const bookClick = value => {
    props.handleBookClick2(value)
  }
  const bookmobileClick = value => {
    props.handleBookMobile(value)
  }
  var videoTour = props.property_video && props.property_video[0]
  var videoTourID = ""
  if (videoTour) {
    videoTourID = getVideoId(videoTour)
  }
  //console.log('property_floorplan',props.property_floorplan && props.property_floorplan)
  function gotohash(event) {
    var thishash = event
    $("html, body").animate(
      {
        scrollTop: $(thishash).offset().top - 120,
      },
      1000
    )
  }

  // History back
  const handleBack = () => {
    // window?.history?.previous?.href()
    var propurl = sessionStorage.getItem("propertyPath")

    if (propurl === null) {
      if (props?.property_type == "sales") {
        propurl =
          "/property/for-sale/in-stoke-newington-and-hackney-and-north-london-and-east-london"
        navigate(propurl)
      }
      if (props?.property_type == "lettings") {
        propurl =
          "/property/to-rent/in-stoke-newington-and-hackney-and-north-london-and-east-london"
        navigate(propurl)
      }
    } else {
      navigate(propurl)
    }
  }


  var featureList = [{
     sales: [
            {key: "tenure", label :"Tenure", prefix: '', suffix: '', price: false},
            {key: "lease_expiry_year", label: "Length of lease", prefix: '', suffix: '', price: false},
            {key: "ground_rent", label :"Annual ground rent amount",  prefix: '', suffix: '', price: true},
            {key: "ground_rent_review_period_years", label :"Ground rent review period",  prefix: '', suffix: '', price: false},
            {key: "service_charge", label :"Annual service charge amount", prefix: '£', suffix: '', price: true},
            {key: "service_charge_period", label :"Service charge review period", prefix: '', suffix: '', price: false},
            {key: "council_tax_band", label :"Council tax band",  prefix: 'Band ', suffix: '', price: false},
            {key: "shared_ownership_percentage_sold", label :"Shared Ownership",  prefix: '', suffix: '%', price: false} 
          ],
      lettings: [
        {key: "tenure", label :"Deposit",  prefix: '', suffix: '', price: false},
        {key: "lease_expiry_year", label: "Length of lease", prefix: '', suffix: '', price: false},
        {key: "council_tax_band", label :"Council tax band", prefix: 'Band ', suffix: '', price: false}
      ]
  }]

  const getFeatureList = (type)=>{
    var listofFeature = [];
    featureList[0][type].map((feature, key)=>{
      if(props.property_extras[feature.key]){
        let value = `${feature.prefix}${props.property_extras[feature.key]}${feature.suffix}`;
        if(feature.price){
          value = `${feature.prefix}${props.property_extras[feature.key].toLocaleString()}${feature.suffix}`
        } 
        listofFeature.push({label: feature.label , value : value}) 
      } 
      if(props.property_extras?.details[feature.key]){
        let value = `${feature.prefix}${props.property_extras?.details[feature.key]}${feature.suffix}`;
        if(feature.price){
          value = `${feature.prefix}${props.property_extras?.details[feature.key].toLocaleString()}${feature.suffix}`
        } 
        listofFeature.push({label: feature.label , value : value}) 
      } 
    }) 
    // console.log('propsprops', listofFeature, featureList)
   return listofFeature
  }
// console.log('propsprops', props)
  return (
    <React.Fragment>
      {/* {props.slides.map((slide, index) => {
      return<> */}
      {/* {ShowSlide || isMobile ? (
        <InView {...inViewOptions}>
                {({ ref, inView }) => (
                  <motion.div
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    
                    className="section"
                  >
          <section className="prop-details-keyfetures">
            <div className="key-wrap-slide-mark">
              <div className="keyfetures" id="section-side1">
                <motion.span variants={fadeInFromLeft} className="small-font-heading">Key Features</motion.span>

                
                {props.property_acc && (
                  <>
                    {isMobileOnly && !props.isLandscapeMode ? (
                      <motion.ul variants={fadeInFromLeft} className="customscrollbar">
                        {props.property_acc.map((acc_item, index) => (
                          <>
                            {index != 0 && (
                              <>
                                <li>
                                  <i className="icon-check-big d-md-none d-xl-block"></i>
                                  <i className="icon-check-big-tilted d-none d-md-block d-xl-none"></i>{" "}
                                  <span>{acc_item}</span>
                                </li>
                              </>
                            )}
                          </>
                        ))}
                      </motion.ul>
                    ) : (
                      <Scrollbars
                        style={{
                          width: "",
                          height: props.LargeMonitor
                            ? 300
                            : props.ClientWidth
                            ? 300
                            : props.LargeBigMonitor
                            ? 600
                            : 300,
                        }}
                      >
                        <motion.ul variants={fadeInFromLeft} className="customscrollbar">
                          {props.property_acc.map((acc_item, index) => (
                            <>
                              {index != 0 && (
                                <>
                                  <li>
                                    <i className="icon-check-big d-md-none d-xl-block"></i>
                                    <i className="icon-check-big-tilted d-none d-md-block d-xl-none"></i>{" "}
                                    <span>{acc_item}</span>
                                  </li>
                                </>
                              )}
                            </>
                          ))}
                        </motion.ul>
                      </Scrollbars>
                    )}
                  </>
                )}

                {props.isLandscapeMode ? 
                 <motion.div variants={fadeInFromLeft} className="button-group-scroll-buttons">
                 <div className="button-scroll-inner">
                   <a href="#section-banner">
                     <strong className="btn-round prev-click">
                       <i className="icon-angle-round-up"></i>
                       Introduction
                     </strong>
                   </a>

                  
                   <a href="#section-details">
                     <strong className="btn-round">
                       <i className="icon-angle-round-down"></i>
                       Details & Gallery
                     </strong>
                   </a>
                 </div>
               </motion.div> :
                !isMobile ?
                  <motion.div variants={fadeInFromLeft} className="button-group-scroll-buttons">
                    <div className="button-scroll-inner">
                      <a href="#section-banner">
                        <strong className="btn-round prev-click">
                          <i className="icon-angle-round-up"></i>
                          Introduction
                        </strong>
                      </a>

                     
                      <a href="#section-details">
                        <strong className="btn-round">
                          <i className="icon-angle-round-down"></i>
                          Details & Gallery
                        </strong>
                      </a>
                    </div>
                  </motion.div>
                : null}
              </div>
            </div>
            <div className="key-banner-wrap">
              <div className="keyfeatures-banner" id="section-side-big1">
              <button className="bouncing-mouse" onClick={props.scrollDown}>
                  <ScrollDownIcon />
                </button>
                {props.property_images && (
                  <>
                    {videoTourID && videoTourID.id && (
                      <strong
                        href="#"
                        className="video-arrow"
                        onClick={e => setPlay(true)}
                      >
                        <i className="icon-video-white"></i>
                      </strong>
                    )}
                    <ImageTransform
                      imagesources={props.property_images[1].url}
                      renderer="srcSet"
                      imagename="property.images.slider"
                      attr={{
                        alt:
                          props.property_display_address +
                          " - Location Location",
                      }}
                      imagetransformresult={processedImages2}
                      id={props.id}
                      testparam={true}
                    />
                  </>
                )}
              </div>
            </div>
            {videoTourID && videoTourID.id && (
              <PlayVideo
                isOpen={isPlay}
                isCloseFunction={setPlay}
                videoId={videoTour}
                isAutoPlay={1}
              />
            )}
          </section>
          </motion.div>
                )}
              </InView>
      ) : (
        ""
      )} */}

      {ShowSlide || isMobile ? (
        <InView {...inViewOptions}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              className="section"
            >
              <Section
                id="detail"
                className="prop-details-keyfetures detail-features"
              >
                <div className="key-wrap-slide-mark">
                  <div className="keyfetures" id="section-side1">
                    <motion.span
                      variants={fadeInFromRight}
                      className="small-font-heading"
                    >
                      {isMobileOnly && (
                        <span className="small-font-heading">Description</span>
                      )}
                      {/* <a
                        href="javascript:void(0)"
                        onClick={handleBack}
                        className="back-to d-none d-xl-inline-flex back-result"
                      >
                        <i className="icon-angle-left d-none d-xl-block slides-back"></i>{" "}
                        <span className="back-to-search">
                          Back to search results
                        </span>
                      </a> */}
                    </motion.span>

                    {props.property_title && (
                      <motion.h2
                        variants={fadeInFromRight}
                        className="sub-heading-keyfeatures"
                      >
                        {props.property_title.replace(/-/g, " ")}
                      </motion.h2>
                    )}

                    {props.property_description && (
                      <motion.div
                        className="padding-right-fix"
                        variants={fadeInFromRight}
                      >
                        <div ref={myRefless}></div>

                        {isMobileOnly || props.isLandscapeMode ? (
                          <div>
                          <ul className="customscrollbar">
                            {active === "read more" ? (
                              <>
                                <div
                                  style={{
                                    display: "inline",
                                    lineHeight: 1.65,
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      props.property_description.slice(0, 300) +
                                      "... ",
                                  }}
                                />
                              </>
                            ) : (
                              <div
                                style={{ display: "inline", lineHeight: 1.65 }}
                                dangerouslySetInnerHTML={{
                                  __html: props.property_description,
                                }}
                              />
                            )}
                            {props.property_description?.length > 200 && (
                              <a
                                className="readmore-l"
                                onClick={handleAccordionClick}
                              >
                                {active}
                              </a>
                            )}
                          </ul>
                            {/* <div className="features"> 
                              {
                              (getFeatureList(props.search_type)).map((feature, key)=>{
                                return   (<div className="list">
                                <span className="key"> {feature.label}  :</span>
                                <span className="value">  {feature.value}</span> 
                              </div>)
                              })
                              }
                            </div> */}
                          </div>
                        ) : (
                          <Scrollbars
                            style={{
                              width: "",
                              height: props.LargeMonitor
                                ? 300
                                : props.ClientWidth
                                  ? 300
                                  : props.LargeBigMonitor
                                    ? 380
                                    : 300,
                            }}
                          >
                            {props.LargeBigMonitor ? (
                              <ul className="customscrollbar">
                                {active === "read more" ? (
                                  <>
                                    <div
                                      style={{
                                        display: "inline",
                                        lineHeight: 1.65,
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          props.property_description.slice(
                                            0,
                                            650
                                          ) + "...",
                                      }}
                                    />
                                  </>
                                ) : (
                                  <div
                                    style={{
                                      display: "inline",
                                      lineHeight: 1.65,
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: props.property_description,
                                    }}
                                  />
                                )}
                                {props.property_description?.length > 650 && (
                                  <a
                                    className="readmore-l"
                                    onClick={handleAccordionClick}
                                  >
                                    {active}
                                  </a>
                                )}
                              </ul>
                            ) : (
                              <ul className="customscrollbar">
                                {active === "read more" ? (
                                  <>
                                    <div
                                      style={{
                                        display: "inline",
                                        lineHeight: 1.65,
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          props.property_description.slice(
                                            0,
                                            300
                                          ) + "...",
                                      }}
                                    />
                                  </>
                                ) : (
                                  <div
                                    style={{
                                      display: "inline",
                                      lineHeight: 1.65,
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: props.property_description,
                                    }}
                                  />
                                )}
                                {props.property_description?.length > 200 && (
                                  <a
                                    className="readmore-l"
                                    onClick={handleAccordionClick}
                                  >
                                    {active}
                                  </a>
                                )}
                              </ul>
                            )}
                            {/* <div className="features"> 
                              {
                              (getFeatureList(props.search_type)).map((feature, key)=>{
                                return   (<div className="list">
                                <span className="key"> {feature.label}  :</span>
                                <span className="value">  {feature.value}</span> 
                              </div>)
                              })
                              }
                            </div> */}
                          </Scrollbars>
                        )}
                      </motion.div>
                    )}
                    {props.isLandscapeMode ? (
                      <motion.div
                        variants={fadeInFromRight}
                        className="button-group-scroll-buttons button-click"
                      >
                        <div className="button-scroll-inner">
                          <a onClick={homeSection.onClick}>
                            <strong className="btn-round prev-click">
                              <i className="icon-angle-round-up"></i>
                              Introduction
                            </strong>
                          </a>

                          {
                            props.property_floorplan[0] !== undefined ?
                              <a onClick={floorplanSection.onClick}>
                                <strong className="btn-round">
                                  <i className="icon-angle-round-down"></i>
                                  Floorplan
                                </strong>
                              </a> :
                              <a onClick={mapSection.onClick}>
                                <strong className="btn-round">
                                  <i className="icon-angle-round-down"></i>
                                  Map
                                </strong>
                              </a>
                          }


                        </div>
                      </motion.div>
                    ) : !isMobile ? (
                      <motion.div
                        variants={fadeInFromRight}
                        className="button-group-scroll-buttons"
                      >
                        <div className="button-scroll-inner">
                          <a onClick={homeSection.onClick}>
                            <strong className="btn-round prev-click">
                              <i className="icon-angle-round-up"></i>
                              Introduction
                            </strong>
                          </a>
                          {
                            props.property_floorplan[0] !== undefined ?
                              <a onClick={floorplanSection.onClick}>
                                <strong className="btn-round">
                                  <i className="icon-angle-round-down"></i>
                                  Floorplan
                                </strong>
                              </a> :
                              <a onClick={mapSection.onClick}>
                                <strong className="btn-round">
                                  <i className="icon-angle-round-down"></i>
                                  Map
                                </strong>
                              </a>
                          }

                        </div>
                      </motion.div>
                    ) : null}
                  </div>
                </div>

                <section>
                  <div className="key-banner-wrap">
                    <div
                      className="keyfeatures-banner property-slide-banner dtfloor gallbal "
                      id="section-side-big1"
                    >
                      <button
                        className="bouncing-mouse"
                        onClick={floorplanSection.onClick}
                      >
                        <ScrollDownIcon />
                      </button>
                      {props.property_images && props.property_images?.length > 0 ? (
                        <>
                          <div className="property-tags d-flex align-items-center justify-content-center">
                            <span
                              className="full-view"
                              onClick={e => openLighboxMobile(e, 0)}
                            >
                              <IconZoom />
                            </span>
                            <span
                              className="slide-count enlarge-view-prop pl-3"
                              onClick={e => openLighboxMobile(e, 0)}
                            >
                              {indexed}/{props.property_images.length} Photos
                            </span>
                          </div>
                          <Slider
                            {...sliderSettings}
                            afterChange={v => afterChangeHandler(v)}
                          >
                            {props.property_images.map(sliderimg => {
                              let processedImages = JSON.stringify({})
                              if (props?.imagetransforms?.images_Transforms) {
                                processedImages =
                                  props.imagetransforms.images_Transforms
                              }
                              const slider_image_url = sliderimg
                              return (
                                <>
                                  <a
                                    className="poptriggergall"
                                    href="javascript:;"
                                    onClick={e => openLighboxMobile(e, 0)}
                                  >
                                    <ImageTransform
                                      imagesources={sliderimg.url}
                                      renderer="srcSet"
                                      imagename="property.images.slider"
                                      attr={{
                                        alt:
                                          props.property_display_address +
                                          " - Location Location",
                                      }}
                                      imagetransformresult={processedImages}
                                      id={props.id}
                                      testparam={true}
                                    />
                                  </a>
                                </>
                              )
                            })}
                          </Slider>

                          {isOpen && (
                            <Lightbox
                              mainSrc={lightImages2[photoIndex]}
                              nextSrc={
                                lightImages2[
                                (photoIndex + 1) % lightImages2.length
                                ]
                              }
                              prevSrc={
                                lightImages2[
                                (photoIndex + lightImages2.length - 1) %
                                lightImages2.length
                                ]
                              }
                              onCloseRequest={() => setIsOpen(false)}
                              onMovePrevRequest={() =>
                                setPhotoIndex(
                                  (photoIndex + lightImages2.length - 1) %
                                  lightImages2.length
                                )
                              }
                              onMoveNextRequest={() =>
                                setPhotoIndex(
                                  (photoIndex + 1) % lightImages2.length
                                )
                              }
                            />
                          )}
                        </>
                      )
                        :
                        (<div className="noImageProperty">
                          <p class="text"><i className="icon-noimage"></i> image coming soon</p>
                        </div>
                        )
                      }
                    </div>
                  </div>
                </section>
              </Section>
            </motion.div>
          )}
        </InView>
      ) : (
        ""
      )}

      {ShowSlide || isMobile ? (
        <InView {...inViewOptions}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              className="section"
            >
              {
                props.property_floorplan[0] !== undefined &&
                <Section
                  id="floorplan"
                  className="prop-details-keyfetures trow-new floor_plan"
                >
                  <div className="key-wrap-slide-mark odd-a">
                    <div
                      className="keyfetures mob-pad-bot-fix"
                      id="section-side2"
                    >
                      {/* <motion.span variants={fadeInFromRight} className="small-font-heading">Floor plan</motion.span>

                <motion.h2 variants={fadeInFromRight} className="sub-heading-keyfeatures">
                  Lorem ipsum dolor sit amet, consectetur adipiscing.
                </motion.h2>

                <motion.ul variants={fadeInFromRight}>
                  <li>
                    <i className="icon-check-big d-md-none d-xl-block"></i>
                    <i className="icon-check-big-tilted d-none d-md-block d-xl-none"></i>{" "}
                    <span>Secure entry, concierge and lift</span>
                  </li>
                  <li>
                    <i className="icon-check-big d-md-none d-xl-block"></i>
                    <i className="icon-check-big-tilted d-none d-md-block d-xl-none"></i>{" "}
                    <span>
                      Expansive 46' reception with floor to ceiling windows
                    </span>
                  </li>
                  <li>
                    <i className="icon-check-big d-md-none d-xl-block"></i>
                    <i className="icon-check-big-tilted d-none d-md-block d-xl-none"></i>{" "}
                    <span>Stylish open-plan kitchen</span>
                  </li>
                  <li>
                    <i className="icon-check-big d-md-none d-xl-block"></i>
                    <i className="icon-check-big-tilted d-none d-md-block d-xl-none"></i>{" "}
                    <span>En suite main bedroom</span>
                  </li>
                </motion.ul> */}
                      {props.isLandscapeMode ? (
                        <motion.div
                          variants={fadeInFromLeft}
                          className="button-group-scroll-buttons button-click"
                        >
                          <div className="button-scroll-inner">
                            <a onClick={detailSection.onClick}>
                              <strong className="btn-round prev-click">
                                <i className="icon-angle-round-up"></i>
                                Details & Gallery
                              </strong>
                            </a>
                            <a onClick={mapSection.onClick}>
                              <strong className="btn-round">
                                <i className="icon-angle-round-down"></i>
                                Map
                              </strong>
                            </a>
                          </div>
                        </motion.div>
                      ) : !isMobile ? (
                        <motion.div
                          variants={fadeInFromLeft}
                          className="button-group-scroll-buttons"
                        >
                          <div className="button-scroll-inner">
                            <a onClick={detailSection.onClick}>
                              <strong className="btn-round prev-click">
                                <i className="icon-angle-round-up"></i>
                                Details & Gallery
                              </strong>
                            </a>
                            <a onClick={mapSection.onClick}>
                              <strong className="btn-round">
                                <i className="icon-angle-round-down"></i>
                                Map
                              </strong>
                            </a>
                          </div>
                        </motion.div>
                      ) : null}
                    </div>
                  </div>

                  <div className="key-banner-wrap odd-b">
                    <div
                      className="keyfeatures-banner property-slide-banner dtfloor "
                      id="section-side-big2"
                    >
                      <div className=" property-tags d-flex align-items-center justify-content-center tags-images">
                        <span
                          className="full-view"
                          onClick={e => openLighboxMobileFloor(e, 0)}
                        >
                          <IconZoom />
                        </span>
                        <span
                          onClick={e => openLighboxMobileFloor(e, 0)}
                          className="slide-count enlarge-view-prop pl-3"
                        >
                          Enlarge
                        </span>
                      </div>
                      <a
                        href="javscript:;"
                        onClick={e => openLighboxMobileFloor(e, 0)}
                        className="flor-a-tag"
                      >
                        <picture>
                          <img
                            src={
                              props.property_floorplan &&
                              props.property_floorplan[0]?.url
                            }
                            alt={
                              props.property_display_address +
                              " Floor Plan - Location Location "
                            }
                          />
                        </picture>
                      </a>
                      {isOpenFloor && (
                        <Lightbox
                          wrapperClassName="floor-modal-image"
                          mainSrc={FloorPlanImage[floorplanIndex]}
                          onCloseRequest={() => setIsOpenFloor(false)}
                          onMovePrevRequest={() =>
                            setFloorPlanIndex(
                              (floorplanIndex + FloorPlanImage.length - 1) %
                              FloorPlanImage.length
                            )
                          }
                          onMoveNextRequest={() =>
                            setFloorPlanIndex(
                              (floorplanIndex + 1) % FloorPlanImage.length
                            )
                          }
                        />
                      )}
                    </div>
                  </div>


                  {/* <button className="bouncing-mouse" onClick={props.scrollDown}>
                  <ScrollDownIcon />
                </button> */}
                </Section>
              }

            </motion.div>
          )}
        </InView>
      ) : (
        ""
      )}

      {ShowSlide && !isMobile ? (
        <InView {...inViewOptions}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              className="section default-map-mobile map-module-detail broadband"
            >
              <Section id="map" className="prop-details-keyfetures">
                <div className="key-wrap-slide-mark section-map-features">
                  <div className="keyfetures" id="section-side3">
                    <motion.span
                      variants={fadeInFromLeft}
                      className="small-font-heading"
                    >
                      {isMobileOnly && (
                        <span className="small-font-heading">
                          Broadband & phone networks
                        </span>
                      )}
                      {/* <a
                        href="javascript:void(0)"
                        onClick={handleBack}
                        className="back-to d-none d-xl-inline-flex back-result"
                      >
                        <i className="icon-angle-left d-none d-xl-block slides-back"></i>{" "}
                        <span className="back-to-search">
                          Back to search results
                        </span>
                      </a> */}
                    </motion.span>

                    <motion.h2
                      variants={fadeInFromLeft}
                      className="sub-heading-keyfeatures"
                    >
                      In this day and age, we know how important it is for you
                      to stay connected.
                    </motion.h2>
                    <motion.ul
                      variants={fadeInFromLeft}
                      className={
                        props.LargeBigMonitor
                          ? "customscrollbar large"
                          : "customscrollbar"
                      }
                    >
                      {props.property_latitude && props.property_longitude && (
                        <BroadBandData
                          Title={""}
                          Latitude={props.property_latitude}
                          Longitude={props.property_longitude}
                        />
                      )}
                    </motion.ul>
                    {props.isLandscapeMode ? (
                      <motion.div
                        variants={fadeInFromLeft}
                        className="button-group-scroll-buttons button-click"
                      >
                        <div className="button-scroll-inner">
                          {
                            props.property_floorplan[0] !== undefined ?
                              <a onClick={floorplanSection.onClick}>
                                <strong className="btn-round prev-click">
                                  <i className="icon-angle-round-up"></i>
                                  Floorplan
                                </strong>
                              </a> :
                              <a onClick={detailSection.onClick}>
                                <strong className="btn-round prev-click">
                                  <i className="icon-angle-round-up"></i>
                                  Details & Gallery
                                </strong>
                              </a>
                          }
                          <a onClick={schoolSection.onClick}>
                            <strong className="btn-round">
                              <i className="icon-angle-round-down"></i>
                              Schools
                            </strong>
                          </a>
                        </div>
                      </motion.div>
                    ) : !isMobile ? (
                      <motion.div
                        variants={fadeInFromLeft}
                        className="button-group-scroll-buttons"
                      >
                        <div className="button-scroll-inner">
                          {
                            props.property_floorplan[0] !== undefined ?
                              <a onClick={floorplanSection.onClick}>
                                <strong className="btn-round prev-click">
                                  <i className="icon-angle-round-up"></i>
                                  Floorplan
                                </strong>
                              </a>
                              :
                              <a onClick={detailSection.onClick}>
                                <strong className="btn-round prev-click">
                                  <i className="icon-angle-round-up"></i>
                                  Details & Gallery
                                </strong>
                              </a>
                          }

                          <a onClick={schoolSection.onClick}>
                            <strong className="btn-round">
                              <i className="icon-angle-round-down"></i>
                              Schools
                            </strong>
                          </a>
                        </div>
                      </motion.div>
                    ) : null}
                  </div>
                </div>
                <div className="key-banner-wrap">
                  <div className="keyfeatures-banner " id="section-side-big3">
                    <button
                      className="bouncing-mouse"
                      onClick={schoolSection.onClick}
                    >
                      <ScrollDownIcon />
                    </button>
                    <div className="property-detail-map details-map">
                      {(isMobile || props.isLandscapeMode) && (
                        <div className="maptriggermobile">
                          <ul>
                            <li className="active-filter-tab">
                              <a
                                href="javascript:;"
                                onClick={e => {
                                  HanldeMapClick("default-map-module")
                                }}
                              >
                                Map
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={e => {
                                  HanldeMapClick("school-map-mobile")
                                }}
                                href="javascript:;"
                              >
                                Schools
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={e => {
                                  HanldeMapClick("transport-map-mobile")
                                }}
                                href="javascript:;"
                              >
                                Transport
                              </a>
                            </li>
                          </ul>
                        </div>
                      )}
                      {/* <PropertyDetailMap
                        isLandscapeMode={props.isLandscapeMode}
                        Title={""}
                        Latitude={props.property_latitude}
                        Longitude={props.property_longitude}
                      /> */}

                      <LocatingMap
                        isLandscapeMode={props.isLandscapeMode}
                        Title={""}
                        Latitude={props.property_latitude}
                        Longitude={props.property_longitude}
                        type="transport"
                        id="first-map"
                      />
                    </div>
                  </div>
                </div>
              </Section>
            </motion.div>
          )}
        </InView>
      ) : (
        ""
      )}

      {ShowSlide && !isMobile ? (
        <InView {...inViewOptions}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              className="section school-map-mobile map-module-detail"
            >
              <Section id="school" className="prop-details-keyfetures odd">
                <div className="key-wrap-slide-mark odd-a section-map-features">
                  <div className="keyfetures" id="section-side4">
                    <motion.span
                      variants={fadeInFromRight}
                      className="small-font-heading"
                    >
                      {isMobileOnly && (
                        <span className="small-font-heading">Schools</span>
                      )}
                      {/* <a
                        href="javascript:void(0)"
                        onClick={handleBack}
                        className="back-to d-none d-xl-inline-flex back-result"
                      >
                        <i className="icon-angle-left d-none d-xl-block slides-back"></i>{" "}
                        <span className="back-to-search">
                          Back to search results
                        </span>
                      </a> */}
                    </motion.span>

                    <motion.h2
                      variants={fadeInFromRight}
                      className="sub-heading-keyfeatures"
                    >
                      Near by Primary and Secondary schools.
                    </motion.h2>
                    <motion.ul
                      variants={fadeInFromRight}
                      className="customscrollbar"
                    >
                      {props.property_latitude && props.property_longitude && (
                        <SchoolsList
                          LargeMonitor={props.LargeMonitor}
                          ClientWidth={props.ClientWidth}
                          Title={""}
                          Latitude={props.property_latitude}
                          Longitude={props.property_longitude}
                        />
                      )}
                    </motion.ul>

                    {props.isLandscapeMode ? (
                      <motion.div
                        variants={fadeInFromRight}
                        className="button-group-scroll-buttons button-click"
                      >
                        <div className="button-scroll-inner">
                          <a onClick={mapSection.onClick}>
                            <strong className="btn-round prev-click">
                              <i className="icon-angle-round-up"></i>
                              Map
                            </strong>
                          </a>
                          <a onClick={transportSection.onClick}>
                            <strong className="btn-round">
                              <i className="icon-angle-round-down"></i>
                              Transport
                            </strong>
                          </a>
                        </div>
                      </motion.div>
                    ) : !isMobile ? (
                      <motion.div
                        variants={fadeInFromRight}
                        className="button-group-scroll-buttons"
                      >
                        <div className="button-scroll-inner">
                          <a onClick={mapSection.onClick}>
                            <strong className="btn-round prev-click">
                              <i className="icon-angle-round-up"></i>
                              Map
                            </strong>
                          </a>
                          <a onClick={transportSection.onClick}>
                            <strong className="btn-round">
                              <i className="icon-angle-round-down"></i>
                              Transport
                            </strong>
                          </a>
                        </div>
                      </motion.div>
                    ) : null}
                  </div>
                </div>
                <div className="key-banner-wrap odd-b">
                  <div className="keyfeatures-banner " id="section-side-big4">
                    <button
                      className="bouncing-mouse"
                      onClick={transportSection.onClick}
                    >
                      <ScrollDownIcon />
                    </button>
                    <div className="property-detail-map details-map">
                      {(isMobile || props.isLandscapeMode) && (
                        <div className="maptriggermobile">
                          <ul>
                            <li>
                              <a
                                href="javascript:;"
                                onClick={e => {
                                  HanldeMapClick("default-map-mobile")
                                }}
                              >
                                Map
                              </a>
                            </li>
                            <li className="active-filter-tab">
                              <a href="javascript:;">Schools</a>
                            </li>
                            <li>
                              <a
                                onClick={e => {
                                  HanldeMapClick("transport-map-mobile")
                                }}
                                href="javascript:;"
                              >
                                Transport
                              </a>
                            </li>
                          </ul>
                        </div>
                      )}
                      <LocatingMap
                        id={"schoollist_frame"}
                        isLandscapeMode={props.isLandscapeMode}
                        Latitude={props.property_latitude}
                        Longitude={props.property_longitude}
                        type="schools"
                      />
                    </div>
                  </div>
                </div>
              </Section>
            </motion.div>
          )}
        </InView>
      ) : (
        ""
      )}

      {ShowSlide && !isMobile ? (
        <InView {...inViewOptions}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              className="section transport-map-mobile map-module-detail"
            >
              <Section id="transport" className="prop-details-keyfetures">
                <div className="key-wrap-slide-mark section-map-features">
                  <div className="keyfetures" id="section-side5">
                    <motion.span
                      variants={fadeInFromLeft}
                      className="small-font-heading"
                    >
                      {isMobileOnly && (
                        <span className="small-font-heading">Transport</span>
                      )}
                      {/* <a
                        href="javascript:void(0)"
                        onClick={handleBack}
                        className="back-to d-none d-xl-inline-flex back-result"
                      >
                        <i className="icon-angle-left d-none d-xl-block slides-back"></i>{" "}
                        <span className="back-to-search">
                          Back to search results
                        </span>
                      </a> */}
                    </motion.span>
                    <motion.h2
                      variants={fadeInFromLeft}
                      className="sub-heading-keyfeatures"
                    >
                      Nearest Transport options from the property location.
                    </motion.h2>

                    <motion.ul
                      variants={fadeInFromLeft}
                      className="customscrollbar"
                    >
                      {props.property_latitude && props.property_longitude && (
                        <TransportList
                          id={"transportlist_frame"}
                          LargeMonitor={props.LargeMonitor}
                          ClientWidth={props.ClientWidth}
                          maxstation="6"
                          Title={""}
                          latitude={props.property_latitude}
                          longitude={props.property_longitude}
                        />
                      )}
                    </motion.ul>

                    {props.isLandscapeMode ? (
                      <motion.div
                        variants={fadeInFromLeft}
                        className="button-group-scroll-buttons button-click"
                      >
                        <div className="button-scroll-inner">
                          <a onClick={schoolSection.onClick}>
                            <strong className="btn-round prev-click">
                              <i className="icon-angle-round-up"></i>
                              Schools
                            </strong>
                          </a>
                          <a onClick={moreSection.onClick}>
                            <strong className="btn-round">
                              <i className="icon-angle-round-down"></i>
                              More Info
                            </strong>
                          </a>
                        </div>
                      </motion.div>
                    ) : !isMobile ? (
                      <motion.div
                        variants={fadeInFromLeft}
                        className="button-group-scroll-buttons"
                      >
                        <div className="button-scroll-inner">
                          <a onClick={schoolSection.onClick}>
                            <strong className="btn-round prev-click">
                              <i className="icon-angle-round-up"></i>
                              Schools
                            </strong>
                          </a>
                          <a onClick={moreSection.onClick}>
                            <strong className="btn-round">
                              <i className="icon-angle-round-down"></i>
                              More Info
                            </strong>
                          </a>
                        </div>
                      </motion.div>
                    ) : null}
                  </div>
                </div>
                <div className="key-banner-wrap">
                  <div className="keyfeatures-banner " id="section-side-big5">
                    <button
                      className="bouncing-mouse"
                      onClick={moreSection.onClick}
                    >
                      <ScrollDownIcon />
                    </button>
                    <div className="property-detail-map details-map">
                      {(isMobile || props.isLandscapeMode) && (
                        <div className="maptriggermobile">
                          <ul>
                            <li>
                              <a
                                href="javascript:;"
                                onClick={e => {
                                  HanldeMapClick("default-map-mobile")
                                }}
                              >
                                Map
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={e => {
                                  HanldeMapClick("school-map-mobile")
                                }}
                                href="javascript:;"
                              >
                                Schools
                              </a>
                            </li>
                            <li className="active-filter-tab">
                              <a href="javascript:;">Transport</a>
                            </li>
                          </ul>
                        </div>
                      )}
                      <LocatingMap
                        Title={" Transport"}
                        id={"transport-map-id"}
                        Latitude={props.property_latitude}
                        Longitude={props.property_longitude}
                        isLandscapeMode={props.isLandscapeMode}
                        type="transport"
                      />
                      {/* <PropertyDetailMap
                        Title={""}
                        Latitude={props.property_latitude}
                        Longitude={props.property_longitude}
                        isLandscapeMode={props.isLandscapeMode}
                      /> */}
                    </div>
                  </div>
                </div>
              </Section>
            </motion.div>
          )}
        </InView>
      ) : (
        ""
      )}

      {isMobile && (
        <InView {...inViewOptions}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              className="map-module-detail map-top-iohine-fix"
            >
              <div ref={mapmyRef}></div>
              <section className="prop-details-keyfetures">
                <div className="key-wrap-slide-mark section-map-features">
                  <div className="keyfetures" id="section-side5">
                    <motion.span
                      variants={fadeInFromLeft}
                      className="small-font-heading"
                    >
                      {/* <a
                        href="javascript:void(0)"
                        onClick={handleBack}
                        className="back-to d-none d-xl-inline-flex back-result"
                      >
                        <i className="icon-angle-left d-none d-xl-block slides-back"></i>{" "}
                        <span className="back-to-search">
                          Back to search results
                        </span>
                      </a> */}
                    </motion.span>

                    <div
                      className={
                        view === "transport-map-mobile"
                          ? "view-show"
                          : "view-hide"
                      }
                    >
                      <span className="small-font-heading">Transport</span>

                      <motion.h2
                        variants={fadeInFromLeft}
                        className="sub-heading-keyfeatures"
                      >
                        Nearest Transport options from the property location.
                      </motion.h2>

                      {props.property_latitude && props.property_longitude && (
                        <TransportList
                          id={"transportlist_frame"}
                          LargeMonitor={props.LargeMonitor}
                          ClientWidth={props.ClientWidth}
                          maxstation="6"
                          Title={""}
                          latitude={props.property_latitude}
                          longitude={props.property_longitude}
                        />
                      )}
                    </div>

                    <div
                      className={
                        view === "school-map-mobile" ? "view-show" : "view-hide"
                      }
                    >
                      <span className="small-font-heading">Schools</span>

                      <motion.h2
                        variants={fadeInFromRight}
                        className="sub-heading-keyfeatures"
                      >
                        Near by Primary and Secondary schools.
                      </motion.h2>

                      <div
                        variants={fadeInFromRight}
                        className="customscrollbar"
                      >
                        {props.property_latitude &&
                          props.property_longitude && (
                            <SchoolsList
                              LargeMonitor={props.LargeMonitor}
                              ClientWidth={props.ClientWidth}
                              Title={""}
                              Latitude={props.property_latitude}
                              Longitude={props.property_longitude}
                            />
                          )}
                      </div>
                    </div>
                    <div
                      className={
                        view === "default-map-mobile"
                          ? "view-show"
                          : "view-hide"
                      }
                    >
                      <span className="small-font-heading">
                        Broadband & phone networks
                      </span>

                      <motion.h2
                        variants={fadeInFromLeft}
                        className="sub-heading-keyfeatures"
                      >
                        In this day and age, we know how important it is for you
                        to stay connected.
                      </motion.h2>

                      {props.property_latitude && props.property_longitude && (
                        <BroadBandData
                          Title={""}
                          Latitude={props.property_latitude}
                          Longitude={props.property_longitude}
                        />
                      )}
                    </div>

                    {props.isLandscapeMode ? (
                      <motion.div
                        variants={fadeInFromLeft}
                        className="button-group-scroll-buttons button-click"
                      >
                        <div className="button-scroll-inner">
                          <a href="#section-schools">
                            <strong className="btn-round prev-click">
                              <i className="icon-angle-round-up"></i>
                              Schools
                            </strong>
                          </a>
                          <a href="#section-more">
                            <strong className="btn-round">
                              <i className="icon-angle-round-down"></i>
                              More Info
                            </strong>
                          </a>
                        </div>
                      </motion.div>
                    ) : !isMobile ? (
                      <motion.div
                        variants={fadeInFromLeft}
                        className="button-group-scroll-buttons"
                      >
                        <div className="button-scroll-inner">
                          <a href="#section-schools">
                            <strong className="btn-round prev-click">
                              <i className="icon-angle-round-up"></i>
                              Schools
                            </strong>
                          </a>
                          <a href="#section-more">
                            <strong className="btn-round">
                              <i className="icon-angle-round-down"></i>
                              More Info
                            </strong>
                          </a>
                        </div>
                      </motion.div>
                    ) : null}
                  </div>
                </div>
                <div className="key-banner-wrap">
                  <div className="keyfeatures-banner " id="section-side-big5">
                    {/* <button
                      className="bouncing-mouse"
                      onClick={props.scrollDown}
                    >
                      <ScrollDownIcon />
                    </button> */}
                    <div className="property-detail-map details-map">
                      {(isMobile || props.isLandscapeMode) && (
                        <div className="maptriggermobile">
                          <ul>
                            <li
                              onClick={e => {
                                HanldeMapClick("default-map-mobile")
                              }}
                              className={
                                view === "default-map-mobile"
                                  ? "active-filter-tab"
                                  : ""
                              }
                            >
                              <a
                                href="javascript:;"
                                onClick={e => {
                                  HanldeMapClick("default-map-mobile")
                                }}
                              >
                                Map
                              </a>
                            </li>
                            <li
                              onClick={e => {
                                HanldeMapClick("school-map-mobile")
                              }}
                              className={
                                view === "school-map-mobile"
                                  ? "active-filter-tab"
                                  : ""
                              }
                            >
                              <a
                                onClick={e => {
                                  HanldeMapClick("school-map-mobile")
                                }}
                                href="javascript:;"
                              >
                                Schools
                              </a>
                            </li>
                            <li
                              onClick={e => {
                                HanldeMapClick("transport-map-mobile")
                              }}
                              className={
                                view === "transport-map-mobile"
                                  ? "active-filter-tab"
                                  : ""
                              }
                            >
                              <a
                                onClick={e => {
                                  HanldeMapClick("transport-map-mobile")
                                }}
                                href="javascript:;"
                              >
                                Transport
                              </a>
                            </li>
                          </ul>
                        </div>
                      )}
                      <div
                        className={
                          view === "transport-map-mobile"
                            ? "view-show"
                            : "view-hide"
                        }
                      >
                        <LocatingMap
                          Title={""}
                          Latitude={props.property_latitude}
                          Longitude={props.property_longitude}
                          isLandscapeMode={props.isLandscapeMode}
                          id="broadbandMobile"
                          type="transport"
                        />
                      </div>
                      <div
                        className={
                          view === "school-map-mobile"
                            ? "view-show"
                            : "view-hide"
                        }
                      >
                        {" "}
                        <LocatingMap
                          id={"schoolslist_framemobile"}
                          isLandscapeMode={props.isLandscapeMode}
                          Latitude={props.property_latitude}
                          Longitude={props.property_longitude}
                          type="schools"
                        />
                      </div>
                      <div
                        className={
                          view === "default-map-mobile"
                            ? "view-show"
                            : "view-hide"
                        }
                      >
                        <LocatingMap
                          isLandscapeMode={props.isLandscapeMode}
                          Title={""}
                          Latitude={props.property_latitude}
                          Longitude={props.property_longitude}
                          id="transportmobile"
                          type="transport"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </motion.div>
          )}
        </InView>
      )}

      {ShowSlide || isMobile ? (
        <Section id="more">
          <div className="section fp-auto-height fp-auto-height-responsive">
            {props.address && props.address?.postcode && (
              <LocalArea
                postcode={
                  props.address && props.address?.postcode?.split(" ")[0]
                }
                postcodefull={props.address && props.address?.postcode}
              />
            )}
            {/* {props.property_office_email} */}
            {/* {props?.area} */}
            {props.property_type != "lettings" && (
              <PropertyCalculator
                propertyPhone={props.property_office_phone}
                property_office_email={
                  props.property_office_email === "sn@locationlocation.com"
                    ? "sn@locationlocation.com"
                    : props.property_office_email ===
                      "andrew@locationlocation.com"
                      ? "thetford@locationlocation.com"
                      : "sn@locationlocation.com"
                }
                propertyPrice={props.property_price}
                propertyaddress={props.property_display_address}
                listing_id={props.listing_id}
                branch={
                  props.property_office_email === "sn@locationlocation.com"
                    ? "Hackney"
                    : props.property_office_email ===
                      "andrew@locationlocation.com"
                      ? "Thetford"
                      : "Hackney"
                }
              />
            )}
            {/* {props.property_type === "sales" && ( */}
            <PropertyReport
              id={props.id}
              property_display_address={props.property_display_address}
              handleBookClick2={props.handleBookClick2}
              listing_id={props.listing_id}
              property_type={props.property_type} 
              propertyPhone={props.property_office_phone}
                property_office_email={
                  props.area === "london"
                    ? "sales@locationlocation.com, adam@at-m.co.uk"
                    :"thetford@locationlocation.com, michael@mortgagerepublic.co.uk" 
                }
                propertyPrice={props.property_price}
                propertyaddress={props.property_display_address} 
                branch={
                  props.property_office_email === "sn@locationlocation.com"
                    ? "Hackney"
                    : props.property_office_email ===
                      "andrew@locationlocation.com"
                      ? "Thetford"
                      : "Hackney"
                }
            />
            {/* )} */}
            <BuyerReport
              id={props.id}
              property_display_address={props.property_display_address}
              handleBookClick2={props.handleBookClick2}
              propertyType={props.property_type}
              branch={props.area}
            />

            <RelatedProperty id={props.id} bookClick={bookClick} />

            {/* <Enquiry bookClick={bookClick} bookmobileClick={bookmobileClick} /> */}

            {/* <LocRatingMap
              isLandscapeMode={props.isLandscapeMode}
              Title={""}
              Latitude={props.property_latitude}
              Longitude={props.property_longitude}
            /> */}

            <div className="prop-details-footer">
              <Footer
                searchtype="details"
                property_type={props.property_type}
                property_display_address={props.property_display_address}
                property_building={props.property_building}
                property_address={props.property_address}
              />
            </div>
          </div>
        </Section>
      ) : (
        ""
      )}
    </React.Fragment>
  )
}

export default PropertySlides
