import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import { Link } from "@StarberryUtils"
import gql from "graphql-tag"
import axios from "axios"
import Header from "../Components/Header/Header"
import BookingHeader from "../Components/Header/BookingHeader"
import PropertyBanner from "../Components/PropertyDetails/PropertyDetailsBanner/PropertyBanner"
import PropertySlides from "../Components/PropertyDetails/KeyFeatures/PropertySlides"
import BookaViewForm from "../Components/ValuationSteps/BookaViewComponent"
import SEO from "../Components/Seo/seo"
import userImgDesk from "../images/Static/stamp-img.png"
import userImgTab from "../images/Static/stamp-img.png"
import userImgMob from "../images/Static/stamp-img.png"
import { BackArrow } from "../Components/icons"
import $ from "jquery"
import { Helmet } from "react-helmet"
import { BookaValuationURL } from "../Components/utils"
import { useLocation } from "@reach/router"
import { useQuery } from '@apollo/client';
import { parse } from "query-string"
import {
  isIOS,
  isMobile,
  withOrientationChange,
  isMobileOnly,
  isTablet,
  isIPad13,
} from "react-device-detect"
import Logo from "../images/locationl-logo.svg"
// import "fullpage.js/vendors/scrolloverflow"; // Optional. When using scrollOverflow:true
import PropertyHeader from "../Components/PropertyDetails/KeyFeatures/propertyheader"
import {
  ScrollingProvider,
  useScrollSections,
  useScrollSection,
  Section,
} from "react-scroll-section"
import { Redirect, navigate } from "@reach/router"
// NOTE: if using fullpage extensions/plugins put them here and pass it as props
const Capitalize = str => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
const pluginWrapper = () => {
  require("./fullpage.scrollOverflowReset.min")
  // require('./fullpage.scrolloverflow.min.js');
}
const PROPERTY_DETAILS = gql`
  query PropertyDetails($url: String!) {
    properties(where: { id: $url }) {
      id
      search_type
      crm_id
      crm_provider
      department
      display_address
      address
      price
      building
      bedroom
      bathroom
      reception
      status
      images
      title
      accomadation_summary
      long_description
      description
      longitude
      latitude
      extra
      virtual_tour
      imagetransforms
      office_details
      floorplan
      office_crm_id
    }
  }
`
const GETOFFICE = gql`
   query GetOfficeQuery ($crm_id: String!){
    ourOffices(where:{Office_crm_id:$crm_id}){
        id
        Office_Name
        Office_crm_id
        Office_Phone
    }
  }
`

const PropertyDetails = props => {
  const { isLandscape, isPortrait } = props
  const [IpadLandscape, setIpadLandscape] = useState(false)
  const [Morescroll, setMorescroll] = useState(false)
  const [firstSlide, setFirstSlide] = useState(false)
  const [ClientWidth, setClientWidth] = useState(false)
  const [LargeMonitor, setLargeMonitor] = useState(false)
  const [LargeBigMonitor, setLargeBigMonitor] = useState(false)
  const [isLandscapeMode, setisLandscapeMode] = useState(false)
  const [isClient, setClient] = useState(true)
  useEffect(() => {
    if (isLandscape && isTablet && $(window).width() > 1200) {
      setIpadLandscape(true)
    } else {
      setIpadLandscape(false)
    }
    if ($(window).width() === 1366 && $(window).height() === 1024) {
      setisLandscapeMode(true)
    } else {
      setisLandscapeMode(false)
    }
    if (isIPad13) {
      setisLandscapeMode(true)
    } else {
      setisLandscapeMode(false)
    }
    //console.log('aaaaaaaaaaa',isLandscape,IpadLandscape);
    if ($(window).width() > 1600) {
      setLargeBigMonitor(true)
    } else if ($(window).width() > 1500) {
      setClientWidth(true)
    } else if ($(window).width() > 1200 && $(window).width() < 1500) {
      setLargeMonitor(true)
    } else {
      setLargeMonitor(false)
    }
  })

  const location = useLocation()
  const searchParams = parse(location.search) // => {init: "true"}
  const ShowBooking = searchParams && searchParams.id
  // Property details
  const [property_details, setPropertyData] = useState("")
  const [loading, setloading] = useState(true)
  //const [metaTitle, setmetaTitle] = useState('');
  //const [metaDesc, setmetaDesc] = useState('');
  const [images1, setimages1] = useState([])
  // Property details

  // Menu fixed scroll
  const [scroll, setScroll] = useState(false)
  // Menu fixed scroll

  const { load, error, data: team_Data } = useQuery(GETOFFICE, {variables: { crm_id: property_details?.office_crm_id }});
  const office_contact_no = property_details?.search_type === 'sales' ? team_Data?.ourOffices[0]?.Office_Phone : '+447862127690';

  useEffect(() => {
    // Property details
    getPropertyData()
    window.scrollTo(0, 0)
    // Property details

    // Menu fixed scroll
    window.addEventListener("scroll", () => {
      if ($(window).width() < 1200) {
        setScroll(window.scrollY > 100)
      } else {
        setScroll(false)
        if (window.scrollY > 100) {
          $(".default-detail").addClass("detail-scroll")
        } else {
          $(".default-detail").removeClass("detail-scroll")
        }
      }
    })
    // Menu fixed scroll
  }, [])

  const [scrollbook, setScrollbook] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollbook(window.scrollY > 100)
      setFirstSlide(window.scrollY > 100 ? true : false)
    })
  }, [])

  const strapiconfig = {
    headers: {
        Authorization:
            `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
  }


  // Property details
  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  let property_slug = pageurl.split("#")[0].split("-")
  let id = property_slug[property_slug.length - 1]
  const getPropertyData = async () => {
    const property_details = await axios
      .get(`${process.env.GATSBY_STRAPI_SRC}/properties/${id}`,strapiconfig)
      .then(result => {
        if (result.data) {
          //
          // console.log(result.data)
          if (result?.data?.publish) {


            setPropertyData(result.data)
            sessionStorage.setItem("data", JSON.stringify(result.data))
            sessionStorage.setItem("property_url", pageurl)
            sessionStorage.setItem("spriftlink", result.data?.Spriftlink)
            sessionStorage.setItem("toemail", result.data?.office_details?.email)
            sessionStorage.setItem("area", result.data?.area)

            //setmetaTitle(result.data.title + ' in ' + result.data.display_address)
            //setmetaDesc('Book a viewing for this ' + result.data.title + ' in ' + result.data.display_address + ' with Location Location at ' + result.data.price + '. Contact Location Location, and get assisted for all your property needs.')
            var imagesArr = []
            for (var i = 0; i < result.data.images.length; i++) {
              imagesArr.push(result.data.images[i].url)
            }
            setimages1(imagesArr)
            setloading(false)
            setClient(false)
          }
          else {
            navigate("/404")
          }
        }
      })
      .catch(err => {
        setloading(false)
        setClient(false)
        //console.log('property_details error', err.response.status);
        setPropertyData("NotFound")
      })
  }
  // Property details

  //
  const [Slide1, setSlide1] = useState("Bookaviewing")
  const [Booking, setBooking] = useState("")
  const [Booking2, setBooking2] = useState("")
  const [Bookingback, setBookingback] = useState("")
  const [Bookingmobile, setBookingmobile] = useState("")
  const [Bookingbackmobile, setBookingbackmobile] = useState("")
  useEffect(() => {
    $("body").addClass("switchtofadein")

    if (!isMobile && ShowBooking === "booking") {
      setBooking("BookaViewing")
      setSlide1("")
    }
    if (isMobile && ShowBooking === "booking" && !Bookingbackmobile) {
      setBookingmobile("BookaViewing")
      setSlide1("")
    }
  }, [])
  const handleClick = value => {
    setSlide1(value)
    setBookingback("")
    setBooking("")
  }

  const handleBookClick = value => {
    setSlide1("")
    setBookingback("")
    setBooking2("")
    setBooking(value)
    $("html, body").animate({ scrollTop: 0 }, 500)
  }
  const handleBookClick2 = value => {
    setSlide1("")
    setBookingback("")
    setBooking(value)
    setBooking2(value)
    $("html, body").animate({ scrollTop: 0 }, 500)
  }

  const closeClick = value => {
    setSlide1(value)
  }

  const handlebackClick = value => {
    setFirstSlide(false)
    setSlide1("Features")
    setBooking("")
    setBookingback(value)
    $("html, body").animate({ scrollTop: 0 }, 500)
  }
  const handlebackClick2 = value => {
    setFirstSlide(false)
    setBooking("")
    setSlide1("Features")
    $("html, body").animate({ scrollTop: 0 }, 500)
  }
  const handleBookMobile = value => {
    setBookingmobile(value)
  }
  const handlebackMobile = value => {
    setBookingbackmobile(value)
    setBookingmobile("")
  }

  const propertyDescription = (property_details) => {
    if(property_details?.crm_provider === "street"){
      if(property_details?.description != null && property_details?.long_description != null){
        return `<p>${property_details?.description}</p> ${property_details?.long_description}`
      } else if(property_details?.long_description != null){
        return `${property_details?.long_description}`
      }
    }else{
     if(property_details?.long_description != null){
        return `${property_details?.long_description}`
      }
    }
    return '';
  }

  // const metaTitle =
  //   property_details.title + " in " + property_details.display_address
  // const metaDesc =
  //   "Explore in detail, " +
  //   property_details.bedroom +
  //   " bedroom " +
  //   property_details.building +
  //   " " +
  //   property_details.status +
  //   " in " +
  //   property_details.display_address +
  //   " with Location Location at " +
  //   property_details.price +
  //   ". Contact us and get assistance in finding the perfect property."
  if (loading)
    return (
      <section className={"loader-wrapper"}>
        <div id="loader-wrapper">
          <div id="loader" class="new-loader">
            <div className="new-loader-anime"></div>
            <img className="logo-white loader-logo" src={Logo} alt="logo" />
          </div>
          <div class="loader-section section-left"></div>
          <div class="loader-section section-right"></div>
        </div>
      </section>
    )

  const action =
    property_details.search_type === "lettings" ? "to rent" : "for sale"
  // const searchType =
  //   property_details.search_type === "lettings" ? "letting" : "sale"

  let propertyType = ""

  if (property_details.building == "") {
    propertyType = "Property"
  } else if (property_details.building == "apartment") {
    propertyType = "Apartment"
  } else if (property_details.building == "house-mid-terrace") {
    propertyType = "House"
  } else if (property_details.building == "flat") {
    propertyType = "Flat"
  } else if (property_details.building == "maisonette") {
    propertyType = "Maisonette"
  } else if (property_details.building == "property") {
    propertyType = "Property"
  } else if (property_details.building == "bungalow") {
    propertyType = "Bungalow"
  } else if (property_details.building == "land") {
    propertyType = "Land"
  } else if (property_details.building == "commercial") {
    propertyType = "Commercial"
  } else {
    propertyType = property_details.building
  }
  const viewReport = sessionStorage.getItem("spriftlink")
  const reportResult = viewReport?.startsWith("https://sprift.com/");
  
  return (
    <React.Fragment>
      <Helmet
        script={[
          {
            src:
              "https://www.locrating.com/scripts/locratingIntegrationScripts.js",
            type: "text/javascript",
          },
        ]}
      />
      {property_details?.title != undefined && property_details?.title != "" && (
        <SEO
          image={property_details?.images && property_details?.images[0]?.url}
          title={`${propertyType} ${action} with ${property_details?.bedroom
            } bedrooms in ${property_details?.display_address
            } at £${property_details?.price?.toLocaleString()}`}
          description={`Explore in detail, ${propertyType?.toLowerCase()} ${action} with ${property_details?.bedroom
            } bedrooms in ${property_details?.display_address
            } with Location Location at £${property_details?.price?.toLocaleString()}. Contact us and get assistance in finding the perfect property.`}
        />
      )}
      <ScrollingProvider>
        {/* <h1 style={{fontSize:"0px", margin: "0px"}}>{Capitalize(property_details.search_type)} with {property_details.bathroom} bedrooms in {property_details.display_address} at £{property_details.price.toLocaleString()}</h1> */}
        {(firstSlide && !Booking && !isMobile) ||
          (firstSlide && IpadLandscape) ? (
          <PropertyHeader
            property_display_address={property_details?.display_address}
            property_type={property_details?.search_type}
            id={property_details?.id}
            property_price={property_details?.price}
            property_title={property_details?.title}
            property_images={property_details?.images}
            property_bedroom={property_details?.bedroom}
            property_bathroom={property_details?.bathroom}
            property_floorplan={property_details?.floorplan}
            property_reception={property_details?.reception}
            property_office_phone={office_contact_no}
            handleBookClick2={handleBookClick2}
          />
        ) : !Booking || Bookingback ? (
          <div
            className={
              scroll ? "menu-fixed menu-scroll " : "menu-fixed default-detail"
            }
          >
            <Header />
          </div>
        ) : (
          ""
        )}
        {!Slide1 && Booking && !Bookingback && (
          <div
            className={
              scrollbook ? "menu-fixed menu-scroll menu2" : "menu-fixed menu2"
            }
          >
            <BookingHeader
              bookslide={Booking2}
              handlebackClick={handlebackClick}
              handlebackClick2={handlebackClick2}
              telephone={property_details?.office_details?.telephone}
            />
          </div>
        )}
        {/* {JSON.stringify(property_details)} */}
        <div className="property-details-wrapper">
          {!Booking && !Bookingmobile && !isClient && (
            <div>
              {(!Booking && !Bookingmobile) || Bookingback ? (
                <Section id="top" class="section">
                  <div className={`bannerprop ${Booking}`}>
                    <PropertyBanner
                      handleClick={handleClick}
                      property_images={property_details?.images}
                      property_display_address={
                        property_details?.display_address
                      }
                      property_currency={property_details?.currency}
                      property_price={property_details?.price}
                      price_qualifier={property_details?.price_qualifier}
                      property_type={property_details?.search_type}
                      property_bedroom={property_details?.bedroom}
                      property_bathroom={property_details?.bathroom}
                      property_reception={property_details?.reception}
                      imagetransforms={property_details?.imagetransforms}
                      id={property_details?.id}
                      handleBookClick={handleBookClick}
                      property_video={property_details?.virtual_tour}
                      listing_id={property_details?.listing_id}
                      area={property_details?.area}
                      status={property_details?.status}
                    />
                  </div>
                </Section>
              ) : (
                <div className="section" />
              )}
              {!Bookingmobile && !Booking && (
                <PropertySlides
                  closeClick={closeClick}
                  slides={"Reasons"}
                  Slide1={Slide1}
                  handleBookClick2={handleBookClick2}
                  handleBookMobile={handleBookMobile}
                  property_title={property_details?.title}
                  property_acc={property_details?.accomadation_summary}
                  property_display_address={property_details?.display_address}
                  property_images={property_details?.images}
                  property_floorplan={property_details?.floorplan}
                  property_price={property_details?.price} 
                  property_description={propertyDescription(property_details)} 
                  property_type={property_details?.search_type}
                  property_longitude={property_details?.longitude}
                  property_latitude={property_details?.latitude}
                  property_bedroom={property_details?.bedroom}
                  property_bathroom={property_details?.bathroom}
                  property_extras={property_details?.extra}
                  search_type={property_details?.search_type}
                  // property_reception={property_details.reception}
                  isLandscapeMode={isLandscapeMode}
                  imagetransforms={property_details?.imagetransforms}
                  id={property_details?.id}
                  address={property_details?.address}
                  property_video={property_details?.virtual_tour}
                  property_office_phone={
                    property_details?.office_details &&
                    property_details?.office_details.telephone
                  }
                  property_office_email={
                    property_details?.office_details &&
                    property_details?.office_details.email
                  }
                  property_building={property_details?.building}
                  property_address={property_details?.address}
                  LargeMonitor={LargeMonitor}
                  LargeBigMonitor={LargeBigMonitor}
                  ClientWidth={ClientWidth}
                  listing_id={property_details?.listing_id}
                  area={property_details?.area}
                />
              )}
            </div>
          )}
        </div>
      </ScrollingProvider>
      {Booking || Bookingmobile ? (
        <div className={`bookingwrapper ${Booking}`}>
          <BookaViewForm
            property_type={property_details?.search_type}
            handlebackMobile={handlebackMobile}
            Booking={Booking}
            Bgimg={property_details?.images && property_details?.images[0]?.url}
            property_display_address={property_details?.display_address}
            property_office_phone={
              property_details?.office_details &&
              property_details?.office_details?.telephone
            }
            pageurl={pageurl}
            property_price={property_details?.price}
            property_address={property_details?.display_address}
            office_crm_id={property_details?.office_crm_id}
            property_office_email={
              property_details?.office_details &&
              property_details?.office_details?.email
            }
            repeatId={property_details?.crm_id}
            property_area={property_details?.area}
            search_type={property_details.search_type}
          />
        </div>
      ) : (
        ""
      )}
      {property_details && (
        <section
          className={
            scroll
              ? "cta-mobile-fixed btn-group-footer prop-detail-sticky d-xl-none"
              : "btn-group-footer prop-detail-sticky d-xl-none"
          }
        >
          <div
            className={
              reportResult == true
                ? "btn-group-footer-block"
                : "btn-group-footer-block none-radius-flex"
            }
          >
            <a
              href="javascript:;"
              onClick={() => setBookingmobile("BookaViewing")}
              className="btn btn-yellow"
            >
              Book a viewing
            </a> 
          </div>
        </section>
      )}
    </React.Fragment>
  )



}

export default withOrientationChange(PropertyDetails)
